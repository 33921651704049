import React from 'react';
import ClassUtils from '@es6/class_utils.es6';

export default class SessionMonitor extends React.Component {
  constructor(props) {
    super(props);
    ClassUtils.autobind(this);
    this.state = { secondsLeft: 1800 };
  }

  render() {
    if (this.state.secondsLeft < 300) {
      return (
        <div className="cmp-modal cmp-session-monitor">
          <div className="modal-box">
            <h2>Session Timeout</h2>
            <p>
              Your session will expire in {this.state.secondsLeft} seconds due
              to inactivity. You can resume your session by clicking the button
              below. If you do nothing, you will be logged out automatically.
            </p>
            <div className="actions">
              <a
                href="#"
                className="button view-link"
                onClick={this.refreshSession}
              >
                Stay logged in
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.syncV2SignOut();
    this.checkTimeLeft();
    this.countDown();

    $(window).focus(() => {
      this.syncV2SignOut();
      this.checkTimeLeft();
    });
  }

  syncV2SignOut() {
    $.post('/api/sync_v2_sign_out');
  }

  checkTimeLeft() {
    let self = this;
    clearTimeout(this.nextTimeCheck);
    $.get('/api/session_time')
      .done(function (data) {
        if (data == undefined) return;
        if (data.time_left == 0) {
          window.location.reload();
        } else {
          let secondsLeft = data.time_left;
          self.setState({ secondsLeft: secondsLeft });
          if (secondsLeft > 300) {
            const nextCheckIn = (secondsLeft - 300) * 1000; // make sure session hasn't been extended
            self.nextTimeCheck = setTimeout(self.checkTimeLeft, nextCheckIn);
          } else {
            self.nextTimeCheck = setTimeout(
              self.checkTimeLeft,
              (secondsLeft + 1) * 1000
            );
          }
        }
      })
      .fail(function (jqXHR, textStatus, errorObj) {
        if (jqXHR.status == 401) window.location.reload();
      });
  }

  countDown() {
    if (this.state.secondsLeft > 0) {
      this.setState({ secondsLeft: this.state.secondsLeft - 1 });
    }
    setTimeout(this.countDown, 1000);
  }

  refreshSession() {
    self = this;
    $.get('/api/refresh_session').done(self.checkTimeLeft);
  }
}
